var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "Footer" }, [
    _c("div", { staticClass: "Footer__Left" }, [
      _c("h3", [_vm._v("Quick link")]),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("HOME")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/about/" } }, [_vm._v("ABOUT")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/news/" } }, [_vm._v("NEWS")])],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/concerts/" } }, [
              _vm._v("CONCERTS")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/instructors/" } }, [
              _vm._v("INSTRUCTORS")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/contact/" } }, [
              _vm._v("CONTACT")
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "Footer__Right" }, [
      _c("h3", [_vm._v("Follow us on")]),
      _vm._v(" "),
      _c("div", { staticClass: "Footer__SNS" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/aoyamagakuinorchestra/"
                }
              },
              [
                _c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-facebook")])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              { attrs: { href: "https://twitter.com/aoyamaorchestra" } },
              [_c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-twitter")])],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.youtube.com/channel/UC9Zy43jAJlgwQ9nMWD-LWPA"
                }
              },
              [_c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-youtube")])],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.instagram.com/aoyamaorchestra/?hl=ja"
                }
              },
              [
                _c("v-icon", { attrs: { large: "" } }, [
                  _vm._v("mdi-instagram")
                ])
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("\n        All rights reserved by AGO\n      ")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }